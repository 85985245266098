import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import langsObj from '../utils/Langs'
import '../assets/stylesheets/index.scss'

export const siteQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        tdsPath
        tmPath
      }
    }
  }
`

const LWCLayout = ({ children, lang, slug }) => {
  const langCode = langsObj.getLangsCodes()
  const isoCode = langCode[lang] ? langCode[lang] : 'en';

  return (
    <StaticQuery
      query={siteQuery}
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet defaultTitle={siteMetadata.title}>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />

            <script src={`${siteMetadata.tdsPath}/scripts/th-element-loader.js`}></script>
            <script src={`${siteMetadata.tmPath}/modules/tm.js`} type="module"></script>
            <script src={`${siteMetadata.tdsPath}/modules/tds.js`} type="module"></script>
            <link href={`${siteMetadata.tdsPath}/css/tds-theme-provider.css`} rel="stylesheet" />
            <link href={`${siteMetadata.tdsPath}/css/tds.css`} rel="stylesheet" />
            <link rel="canonical" href={`https://trailhead.salesforce.com/${slug}`} />

            <meta http-equiv="content-language" content={isoCode} />
            <link href={`https://trailhead.salesforce.com/${slug}`} hreflang="x-default" rel="alternate"></link>
            {
              Object.keys(langCode).map(key => {
                return (<link href={`https://trailhead.salesforce.com/${key}/${slug}`} hreflang={langCode[key]} rel="alternate"></link>)
              })
            }
          </Helmet>
          <main className="dsc-content">
            {children}
          </main>
        </>
      )}
    />
  )
}

LWCLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default LWCLayout
